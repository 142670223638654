import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-upload"
export default class extends Controller {
  static targets = [
    "fileInput",
    "submit",
    "filename"
  ]

  validateFile(event) {
    const file = event.target.files[0]
    if (file.type == "text/csv") {
      this.filenameTarget.textContent = file.name
      this.submitTarget.disabled = false
    }
  }
}
