import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="paddle"
export default class extends Controller {
  connect() {
    this.#initializePaddle()
    this.#openCheckout()
  }

  #initializePaddle() {
    Paddle.Initialize({
      token: document.head.querySelector("[name='paddle:token']").content,
      checkout: {
        settings: {
          allowLogout: false
        }
      },
      eventCallback: (event) => {
        if (event.name == "checkout.completed") {
          Turbo.visit("/billing?status=checkout.completed")
        }
      }
    });
  }

  #openCheckout() {
    var checkoutParams = {
      settings: {
        variant: "one-page",
        displayMode: "inline",
        frameTarget: this.element.className,
        frameInitialHeight: "450",
        frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;"
      }
    }

    const transactionId = document.head.querySelector("[name='paddle:transaction:id']")?.content
    if (transactionId) {
      checkoutParams["transactionId"] = transactionId
    } else {
      checkoutParams["items"] = [{
        quantity: 1,
        priceId: document.head.querySelector("[name='paddle:selected_price']").content
      }]

      checkoutParams["customer"] = {
        id: document.head.querySelector("[name='paddle:customer:id']").content
      }
    }

    Paddle.Checkout.open(checkoutParams)
  }
}
